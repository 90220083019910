<template>
    <v-list-item dense >
        <v-list-item-avatar >
                <v-img max-width="100%" :src="photoURL" ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title  >
                {{ displayName }}
            </v-list-item-title>
             <v-list-item-subtitle v-if="disabled" class="warning--text ">
                <v-icon color="warning" small>mdi-account-cancel</v-icon>
                {{ 'disabled' | t }}
            </v-list-item-subtitle>
           
        </v-list-item-content>
    </v-list-item>
</template>
<script>
export default {
    props: {
        props:Object,
        field:Object,
        item:{
            type: Object,
        },
        disabled: Boolean
    },
    computed: {
        displayName () {
            const { item, props} = this
            return item && item.displayName ? item.displayName : (props && props.value && props.value.displayName ? props.value.displayName : '-')
        },
        photoURL () {
            const { item, props, src} = this
            return item && item.photoURL ? item.photoURL : (props && props.value && props.value.photoURL ? props.value.photoURL : src)

        }
    },
    data: () => ({
        src: require('@/assets/profile-img.png')
    })
}
</script>