<template>
    <v-chip small outlined>
        <v-icon class="mx-1" >
           {{ icon }} 
        </v-icon>
        <small>
            {{date}}
        </small>
    </v-chip>
</template>
<script>
import moment from 'moment'
export default {
    props: {
        time:{
            type: [Date, String],
        },
        icon: {
            type: String,
            default: 'mdi-calendar-outline'
        },
        type: {
            type: String,
            default: 'calendar'
        }
    },
    computed: {
        date () {
            let { time } = this
            time = typeof time.toDate == 'function' ?  time.toDate() : time
            const date = moment(time)
            if (this.type == 'calendar')
                return date.calendar()
            else
                return date.fromNow()
        }
    },
    created () {
        moment.locale(this.$vuetify.lang.current)
    }
}
</script>