<template>
  
  <v-layout d-flex column class="fh" id="items">
      <v-flex shrink  class="table-section d-flex align-center px-6"  >
        
        <v-skeleton-loader v-if="loading"    
                  :type="  'actions'"
                ></v-skeleton-loader>
        <v-toolbar  flat dense v-else>
          <v-btn color="success" depressed rounded  to="/dashboard/users/new"> 
            <v-icon class="mx-1">mdi-account-plus</v-icon>
            {{ 'add_user' | t }}
          </v-btn>
        </v-toolbar>
    </v-flex>
    <v-flex grow class="relative body" style="z-index: 0" >
      <div class="absolute fh fw  " >
        <div class="relative fh fw auto-y-overflow px-6" ref="wraper">
           
            <!-- :hide-default-header="breakpoint.smAndUp" -->
            <v-data-table
            :height="getHeight()"
            hide-default-footer
            :loading="loading"
            fixed-header
            :headers="headers"
            :items="loading ? [] : items"
            item-key="id"
            :options="options"
             @update:options="paginate"

            > 
           
              <template v-slot:loading>
                <v-skeleton-loader
                class="my-2"
                  :type=" breakpoint.smAndUp ? 'table-tbody' : 'card, card'"
                  :width="breakpoint.smAndUp ? null : breakpoint.width - 33"
                ></v-skeleton-loader>
              </template>

             <template v-slot:item.displayName="{ item }">
               <CellItemUser :item="item" :disabled="item.disabled" />
             </template>
             <template v-slot:item.role="{ item }">
               <v-icon small >{{  getRole(item.customClaims.role).icon }}</v-icon>
              <small>
                {{  getRole(item.customClaims.role).text }} 
              </small>
             </template>

              <template v-slot:item.email="{ item }">
                <a :href="`mailto:/${item.email}`">{{item.email}}</a>
              </template>
              <template v-slot:item.phoneNumber="{ item }">
                <a :href="`tel:/${item.phoneNumber}`">{{item.phoneNumber}}</a>
              </template>
           
              <template v-slot:item.metadata.creationTime="{ item }">
               <CreationTime :time="item.metadata.creationTime" />
              </template>
              <template v-slot:item.metadata.lastSignInTime="{ item }">
               <CreationTime v-if="item.metadata.lastSignInTime" :time="item.metadata.lastSignInTime" icon="mdi-clock" type="now" />
              </template>
              <template v-slot:item.settings="{ item }">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-subheader>
                      {{ 'settings' | t }}
                    </v-subheader>
                    <v-list-item
                      v-for="(btn, n) in actionBtns" :key="n" 
                      v-on="btn.on ? btn.on(item) : {}"
                      :to="btn.to ? btn.to(item) : null"
                      
                      
                    >
                   
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon small v-if="typeof btn.icon == 'function' ? btn.icon(item) : btn.icon" :color="typeof btn.color == 'function' ? btn.color(item) : btn.color" class="mx-1">{{typeof btn.icon == 'function' ? btn.icon(item) : btn.icon}}</v-icon>
                          {{ typeof btn.label == 'function' ? btn.label(item) : btn.label | t }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
 
             </template>
            </v-data-table>
        </div>
      </div>
    </v-flex>
    <v-flex class="elevation-2 table-section table-footer px-6"  >
         <!-- <v-skeleton-loader v-if="loading"
                  class="pa-1"
                  type="table-tfoot"
                ></v-skeleton-loader> -->
        <v-data-footer
          :class="{ mobile: !breakpoint.smAndUp }"
          :options="options"
          :pagination="pagination"
          @update:options="paginate" 
          :items-per-page-options="footerProps['items-per-page-options']"
        >
        </v-data-footer>
    </v-flex>
  </v-layout>
</template>
<script> 
import $ from 'jquery'
import CellItemUser from '@/components/cells/User'
import CreationTime from '@/components/cells/CreationTime'
export default {
    name: 'Users',
    components:{ 
      CellItemUser,
      CreationTime
    },
    data: () => ({
      dialog: true,
        items: [],
        loading: false,
        options: {
          page: 1,
          itemsPerPage: 5,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false
        },
        pagination: {
          page: 1,
          itemsPerPage: 10,
          get pageStart() {
            return (this.page - 1) * this.itemsPerPage
          },
          get pageStop() {
            return (this.page * this.itemsPerPage) 
          },
          pageCount: 0,
          itemsLength: 0
        }
    }),
    watch: {
      '$route': {
        handler: 'updateTable',
        immediate: true
      }
    },
    methods: {
      routeTo (e) {
        console.log(e);
      },
      getHeight () {
        const {wraper} = this.$refs
        if (!wraper) return 0
        const { height } = wraper.getBoundingClientRect()
        return height
      },
      paginate (options) {
         const { page, itemsPerPage, sortBy, sortDesc} = options
            const query = Object.assign({},this.$route.query) // copy the query object to avoid routing to the same route error
            if (query['sortBy[]'])
                delete query['sortBy[]']
            if (query['sortDesc[]'])
                delete query['sortDesc[]']
            
            const pushRoute = query.page < page
            query.page = page
           
            query.limit = itemsPerPage
            if (sortBy.length)
                query.sortBy = sortBy
            if (sortDesc.length)    
                query.sortDesc = sortDesc
            const uri =  this.$route.path + '?' + $.param(query)
            if ($.param(query) != $.param(this.$route.query))
                if (pushRoute)
                    this.$router.push(uri)
                else
                    this.$router.replace(uri)
      },

      updateTable ({ query }) {
        let { page, limit:  itemsPerPage} = query
        page = page || 1
        itemsPerPage = itemsPerPage || 10
        this.options.page = this.pagination.page = Number(page)
        this.options.itemsPerPage = this.pagination.itemsPerPage = Number(itemsPerPage)
        this.fetchUsers()
      },
      fetchUsers () {
        this.loading = true
      
        this.$api.get(`/users?${$.param(this.$route.query)}`).then(response => {
           this.items = response.data
        }).catch(this.handelError).finally(() => this.loading = false)

      
      }
    },
    computed: {
      actionBtns () {
        const self = this
        return [
          {
            icon: 'mdi-circle-edit-outline',
            color: 'primary',
            label: 'edit',
            to (item) {
              return '/dashboard/users/' + item.uid
            }

          },
        
          {
            icon: user => user.disabled ? 'mdi-account-check-outline' : 'mdi-account-cancel-outline',
            color: user => user.disabled ? 'success' : 'warning',
            label: user => user.disabled ? 'enable' : 'disable',
            on: (user) => ({
              click () { 
                
                self.openDialog('confirm', {
                  title: user.disabled ? 'confirm_user_enable' : 'confirm_user_disable',
                  text: user.disabled ? 'confirm_user_enable_text' : 'confirm_user_disable_text',
                  name: user.displayName,
                  confirmText:  user.disabled ? 'enable' : 'disable',
                  confirmIcon: user.disabled ? 'mdi-account-check' : 'mdi-account-cancel',
                  color: user.disabled ? 'success' : 'warning',
                }, () => {
                 
                  self.dialogLoading(true)
                  self.$api.put('/users/'+user.uid, { disabled:  !user.disabled})
                  .then(({ data }) => {
                      user.disabled = data.disabled
                      self.notify(self.$t(`user_${data.disabled ? 'enabled' : 'disabled'}_successfuly`, user.displayName), {
                        color: 'success',
                        icon: 'mdi-check-circle'
                      })
                      self.dialogLoading(false)
                      self.closeDialog()
                  }).catch(self.handelError).finally(() => self.dialogLoading(false))
               
                }) 
              }
            })
            
          },
          {
            icon: 'mdi-delete',
            color: 'error',
            label: 'delete',
            on: (user) => ({
              click () { 
                self.openDialog('confirm', {
                  title: 'confirm_user_deletion',
                  text: 'confirm_user_deletion_text',
                  name: user.displayName,
                  confirmText: 'delete_account',
                  confirmIcon: 'mdi-delete',
                  color: 'error'
                }, () => {
                  self.dialogLoading(true)
                  self.$api.delete('/users/'+user.uid)
                  .then(() => {
                      self.notify(self.$t('user_deleted_successfuly', user.displayName), {
                        color: 'success',
                        icon: 'mdi-check-circle'
                      })
                      self.dialogLoading(false)
                      self.closeDialog()
                  }).catch(self.handelError).finally(() => self.dialogLoading(false))
               
                }) 
              }
            })
            
          },
        ]
      },
      collection () {
        return this.database.collection('users')
      },
      footerProps () {
          let limits = [10, 25, 50, 100]
          const qLimit = Number(this.$route.query.limit)
          if(!isNaN(qLimit) && limits.indexOf(qLimit) == -1)
              limits.push(qLimit)
          return {'items-per-page-options':  limits}
      },
      sortBy () {
          const { query } = this.$route
          if (query['sortBy[]'])
              return Array.isArray(query['sortBy[]']) ? query['sortBy[]'] : [query['sortBy[]']]
          else
              return undefined
      },
      sortDesc () {
          const { query } = this.$route
          if (query['sortDesc[]'])
              return Array.isArray(query['sortDesc[]']) ? query['sortDesc[]'].map(e => eval(e)) : [eval(query['sortDesc[]'])]
          else
              return undefined
      },
     
      headers () {
        const __ = t => this.$t('users_headers.'+t) 
        return [
          { 
            text: __('displayName'),
            value: 'displayName',
            align: 'start',
          },
          { text: __('displayName'), value: 'role' },
          { text: __('email'), value: 'email' },
          { text: __('phoneNumber'), value: 'phoneNumber' },
          { text: __('lastSignInTime'), value: 'metadata.lastSignInTime' },
          { text: __('creationTime'), value: 'metadata.creationTime' },
          { value: 'settings', align: 'end' },
        ]
      },
    }
}
</script>

<style lang="scss">
#items {
  .table-section {
    max-height: 58px; 
    flex-basis: 58px; 
    z-index: 1;
  }
  .table-section.table-footer {
    max-height: 58px; 
    flex-basis: 58px; 
  }
  .container {
    padding: 0;
    z-index: 1;
  }
  .v-skeleton-loader__table-tfoot {
    padding: 5px;
  }

  .v-data-footer.mobile {
    .v-data-footer__select,
    .v-data-footer__pagination {
      margin: auto;
    }
    .v-data-footer__select{

      height: 48px;
    }
  }
}
</style>