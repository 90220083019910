var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"fh",attrs:{"d-flex":"","column":"","id":"items"}},[_c('v-flex',{staticClass:"table-section d-flex align-center px-6",attrs:{"shrink":""}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":'actions'}}):_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-btn',{attrs:{"color":"success","depressed":"","rounded":"","to":"/dashboard/users/new"}},[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm._f("t")('add_user'))+" ")],1)],1)],1),_c('v-flex',{staticClass:"relative body",staticStyle:{"z-index":"0"},attrs:{"grow":""}},[_c('div',{staticClass:"absolute fh fw  "},[_c('div',{ref:"wraper",staticClass:"relative fh fw auto-y-overflow px-6"},[_c('v-data-table',{attrs:{"height":_vm.getHeight(),"hide-default-footer":"","loading":_vm.loading,"fixed-header":"","headers":_vm.headers,"items":_vm.loading ? [] : _vm.items,"item-key":"id","options":_vm.options},on:{"update:options":_vm.paginate},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"my-2",attrs:{"type":_vm.breakpoint.smAndUp ? 'table-tbody' : 'card, card',"width":_vm.breakpoint.smAndUp ? null : _vm.breakpoint.width - 33}})]},proxy:true},{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('CellItemUser',{attrs:{"item":item,"disabled":item.disabled}})]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.getRole(item.customClaims.role).icon))]),_c('small',[_vm._v(" "+_vm._s(_vm.getRole(item.customClaims.role).text)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:/" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("tel:/" + (item.phoneNumber))}},[_vm._v(_vm._s(item.phoneNumber))])]}},{key:"item.metadata.creationTime",fn:function(ref){
var item = ref.item;
return [_c('CreationTime',{attrs:{"time":item.metadata.creationTime}})]}},{key:"item.metadata.lastSignInTime",fn:function(ref){
var item = ref.item;
return [(item.metadata.lastSignInTime)?_c('CreationTime',{attrs:{"time":item.metadata.lastSignInTime,"icon":"mdi-clock","type":"now"}}):_vm._e()]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical-circle-outline")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm._f("t")('settings'))+" ")]),_vm._l((_vm.actionBtns),function(btn,n){return _c('v-list-item',_vm._g({key:n,attrs:{"to":btn.to ? btn.to(item) : null}},btn.on ? btn.on(item) : {}),[_c('v-list-item-content',[_c('v-list-item-title',[(typeof btn.icon == 'function' ? btn.icon(item) : btn.icon)?_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":typeof btn.color == 'function' ? btn.color(item) : btn.color}},[_vm._v(_vm._s(typeof btn.icon == 'function' ? btn.icon(item) : btn.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("t")(typeof btn.label == 'function' ? btn.label(item) : btn.label))+" ")],1)],1)],1)})],2)],1)]}}])})],1)])]),_c('v-flex',{staticClass:"elevation-2 table-section table-footer px-6"},[_c('v-data-footer',{class:{ mobile: !_vm.breakpoint.smAndUp },attrs:{"options":_vm.options,"pagination":_vm.pagination,"items-per-page-options":_vm.footerProps['items-per-page-options']},on:{"update:options":_vm.paginate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }